import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Rodape extends Component {

  constructor(props) {
    super(props);
  }

  data_atual = new Date();

  render() {
    return (
      <footer className="footer bg-2 bg-overlay-black-90">
        <div className="container">
          <div className="row">

            <a  className='whatsappFlutuante' href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone2, "link") } target='_blank' >
              <i className="fa fa-whatsapp"></i>
            </a>

            <div className="col-md-12">
              <div className="social">
                <ul>
                  <li>
                    <a href={ this.props.revenda.facebook_link } target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook-square"></i></a>
                  </li>
                  <li>
                    <a href={ this.props.revenda.instagram_link } target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" target="_blank"></i></a>
                  </li>
                  <li>
                    <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone2, "link") }><i className="fa fa-whatsapp"></i></a>
                  </li>
                  <li>
                    <a target="_blank" href={ this.props.revenda.mapa_link } className="wow slideInLeft" rel="noopener noreferrer" ><i className="fa fa-map-marker"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row rodape-bottom">
            <div className="col-lg-3 col-md-6">
              <div className="about-content">
                <img className="img-fluid" id="logo-footer" src="images/logo.png" alt=""></img>
                <h6 className="text-white">Informações da loja</h6>
                <p className="text-gray">
                  Edcar Veículos Qualidade e Compromisso! Trabalhamos melhores taxas e pegamos seu veículo na troca.
                  Ha mais de 25 anos no ramo de compra, venda e troca de veículos novos e seminovos.
                </p>
              </div>

            </div>
            <div className="col-lg-3 col-md-6">
              <div className="usefull-link">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3653.11411741344!2d-46.5534084!3d-23.7076185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce435355f236f5%3A0xc484cdd6021ef811!2sEdcar%20Ve%C3%ADculos!5e0!3m2!1spt-BR!2sbr!4v1676397931780!5m2!1spt-BR!2sbr"></iframe>
                <h6 className="text-white">Informações da loja</h6>
                <div className="address">
                  <ul className="text-sobrenos-info">
                    <li>
                      <a target="_blank" href={ this.props.revenda.mapa_link } className="wow slideInLeft" rel="noopener noreferrer" >
                        { this.props.revenda.peendereco_fiscal } - { this.props.revenda.peendereco_fiscal_numero } - { this.props.revenda.pebairro_fiscal } - { this.props.revenda.pecidade } - { this.props.revenda.peuf }
                      </a>
                    </li>
                    <li>
                      <a href={ "tel:" + retiraMascara(this.props.revenda.petelefone1, "link") } >
                        <i className="fa fa-phone"></i>
                        { retiraMascara(this.props.revenda.petelefone1) }
                      </a>
                    </li>
                    {/* <li>
                    <a target="_blank" href="https://linktr.ee/uniautosatend">
                      <i className="fa fa-whatsapp"></i>
                      WHATSAPPs
                      </a>
                    </li> */}
                    <li>
                      <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone2, "link") }>
                        <i className="fa fa-whatsapp"></i>
                        { retiraMascara(this.props.revenda.petelefone2) }
                        </a>
                    </li>
                    <li>
                      <a href={ "mailto:" + this.props.revenda.peemail }>
                        <i className="fa fa-envelope-o" style={{marginRight: "10px"}}> </i>
                        { this.props.revenda.peemail }</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <hr />
          <div className="copyright">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="text-lg-left text-center">
                  <p className="text-gray">©Copyright 2022 Edcar Veículos by <a href="https://www.intermedioveiculos.com.br/" target="_blank">Intermédio Veículos</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    );
  }
}

function retiraMascara(value, type = "texto") {
  var numero = '';
  if (value !== undefined) {
    numero = value.replace('_', '');
    if (type === "link") {
      numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    }
  }
  return numero;
}

export default Rodape;