import { id_firebase } from './content-site';
import { firestore } from './firebase-config';


export default function XMLIntegracoes() {

    firestore.collection("revenda").doc(id_firebase).collection("veiculos")
        .where("veremovido", "==", 0).where("vevendido", "==", 0).orderBy("updated_at", "desc")
        .get()
        .then(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id = doc.id;
                if (document["veexibir_site"] !== false) {
                    items.push(document);
                }
            });

            // Função para escapar caracteres especiais no XML
            function escapeXml(unsafe) {
                return unsafe.replace(/[<>&'"]/g, function (c) {
                    switch (c) {
                        case '<': return '&lt;';
                        case '>': return '&gt;';
                        case '&': return '&amp;';
                        case '\'': return '&apos;';
                        case '"': return '&quot;';
                    }
                });
            }

            // Função para gerar a string XML
            function listaXML(allVehicles) {
                let xmlDoc = document.implementation.createDocument("", "", null);
                let rss = xmlDoc.createElement("rss");
                rss.setAttribute("version", "2.0");
                rss.setAttribute("xmlns:g", "http://base.google.com/ns/1.0");

                let channel = xmlDoc.createElement("channel");

                let title = xmlDoc.createElement("title");
                title.textContent = "Catálogo de Veículos";
                channel.appendChild(title);

                let link = xmlDoc.createElement("link");
                link.textContent = "https://edcarveiculos.com.br/#/";
                channel.appendChild(link);

                let description = xmlDoc.createElement("description");
                description.textContent = "Catálogo de veículos para venda";
                channel.appendChild(description);

                allVehicles.forEach(item => {
                    let itemElement = xmlDoc.createElement("item");

                    let idLoja = xmlDoc.createElement("g:idStore");
                    idLoja.textContent = escapeXml(item.created_id);
                    itemElement.appendChild(idLoja);

                    let placa = xmlDoc.createElement("g:plate");
                    placa.textContent = escapeXml(item.veiplaca);
                    itemElement.appendChild(placa);

                    let description = xmlDoc.createElement("g:description");
                    description.textContent = escapeXml(`Brand: ${item.vefipe_marca},
                                        Model: ${item.vefipe_name},
                                        Km: ${item.vequilometragem},
                                        Fabrication: ${item.veano_fabricacao},
                                        Model year: ${item.vefipe_ano_modelo},
                                        Fuel: ${item.vefipe_combustivel},
                                        Car gearbox: ${item.vecambio},
                                        Color: ${item.vecor},
                                        Bodywork: ${item.vecarroceria},
                                        Doors: ${item.veportas}`);

                    itemElement.appendChild(description);

                    let allowedTypes = ['carro', 'moto', 'caminhao', 'outros'];
                    if (allowedTypes.includes(item.vetipoveiculo)) {
                        let type = xmlDoc.createElement("g:type");
                        type.textContent = escapeXml(item.vetipoveiculo);
                        itemElement.appendChild(type);
                    }

                    if (item.opcionais && item.opcionais.length > 0) {
                        item.opcionais.forEach(opcional => {
                            let opcionalElement = xmlDoc.createElement("g:opcional");
                            opcionalElement.textContent = escapeXml(opcional.descricao);
                            itemElement.appendChild(opcionalElement);
                        });
                    }

                    let fipeId = xmlDoc.createElement("g:fipeId");
                    fipeId.textContent = ""
                    itemElement.appendChild(fipeId)

                    let armored = xmlDoc.createElement("g:armored");
                    armored.textContent = item.veblindado === false ? "Não" : "Sim";
                    itemElement.appendChild(armored)

                    let model = xmlDoc.createElement("g:model");
                    model.textContent = escapeXml(item.ve_modelo || "")
                    itemElement.appendChild(model)

                    let version = xmlDoc.createElement("g:version");
                    version.textContent = escapeXml(item.ve_versao || "")
                    itemElement.appendChild(version)

                    let link = xmlDoc.createElement("g:link");
                    link.textContent = escapeXml(`https://edcarveiculos.com.br/#/${item.id}`);
                    itemElement.appendChild(link);

                    item.imagens.forEach(imagem => {
                        let image_link = xmlDoc.createElement("g:image_link");
                        image_link.textContent = escapeXml(imagem.fileUrl || "");
                        itemElement.appendChild(image_link);
                    })

                    let condition = xmlDoc.createElement("g:condition");
                    condition.textContent = item.veveiculonovo === 1 ? 'new' : 'used';
                    itemElement.appendChild(condition);

                    let availability = xmlDoc.createElement("g:availability");
                    availability.textContent = item.vevendido === 0 ? 'in stock' : 'out of stock';
                    itemElement.appendChild(availability);

                    let price = xmlDoc.createElement("g:price");
                    price.textContent = `${item.vevalor} BRL`;
                    itemElement.appendChild(price);

                    let brand = xmlDoc.createElement("g:brand");
                    brand.textContent = escapeXml(item.vefipe_marca || "");
                    itemElement.appendChild(brand);

                    channel.appendChild(itemElement);
                });

                rss.appendChild(channel);
                xmlDoc.appendChild(rss);

                let serializer = new XMLSerializer();
                let xmlString = `<?xml version="1.0" encoding="UTF-8"?>\n` + serializer.serializeToString(xmlDoc);
                return xmlString;
            }

            function saveFile(nomeArquivo, conteudo) {
                const blob = new Blob([conteudo], { type: "application/xml" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = nomeArquivo;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            const xmlString = listaXML(items);
            saveFile("estoque.xml", xmlString);
        })
}
